@keyframes aiStarsLoading {
	0% {
		opacity: 1;
		transform: scale(1.3);
	}
	50% {
		opacity: 0.5;
		transform: scale(1);
	}
	100% {
		opacity: 1;
		transform: scale(1.3);
	}
}

.modal-dialog.simpleDialog.aboutMeAiPopup {
	max-width: 400px;
	.closeBtnAi {
		position: absolute;
		top: 1.1rem;
		// top: calc(1.1rem + env(safe-area-inset-top));
		right: 0px;
		z-index: 2222;
		button {
			border: none;
		}
	}

	.headerContent {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		// padding-top: calc(1.5rem + env(safe-area-inset-top)) !important;
		padding-top: 1.5rem;

		.shareAi {
			position: absolute;
			left: 1.5rem;
			top: 1.2rem;
			// top: calc(1.2rem + env(safe-area-inset-top));
			cursor: pointer;
			display: flex;
			flex-direction: column;
			gap: 2px;
			font-size: 20px;
			span {
				color: #2d5086;
				font-size: 12px;
			}
		}
	}
	.aiContent {
		min-height: 30vh;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		padding: 0 20px !important;

		.starsLoading {
			animation: aiStarsLoading 2s infinite;
			color: #2d5086;
			font-size: 60px;
		}
		.innerAiContent {
			align-self: start;
			margin-bottom: 40px;
			white-space: pre-wrap;
			text-align: justify;
			width: 100%;
		}
	}
}

// .modal-dialog.simpleDialog.aboutMeAiPopup.matchAiPopup {
// }

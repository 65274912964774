@keyframes fadeInBanner {
	0% {
		opacity: 0;
	}
	70% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.bottomStrip,
.sale-strip,
.sale-new-button-wrap,
.sale-button-wrap,
.blue-box-subscription,
.videoDate-strip,
.video-dating-banner-strip {
	animation: fadeInBanner 2s forwards;
}

.sale-new-button-wrap {
	position: fixed;
	bottom: 20px;
	z-index: 222;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	.sale-button {
		border-radius: 49.11px;
		background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
			url("../../images/newSubscription/matzot.png") no-repeat center;
		background-size: cover;
		background-position: center top -68px;
		box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.35);
		display: flex;
		width: 290px;
		height: 40px;
		padding: 23px;
		justify-content: center;
		align-items: center;
		gap: 15.47px;
		flex-shrink: 0;

		z-index: 222;
		width: auto !important;
		color: #fff;

		font-size: 25px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
}

.videoDate-strip {
	position: fixed;
	bottom: calc(85px + env(safe-area-inset-bottom));
	left: 10px;
	z-index: 222;
	display: flex;
	width: fit-content !important;
	.closeIcon {
		position: absolute;
		right: 0;
		top: 0;
		width: 12px;
		height: 12px;
		color: #2d5086;
		background-color: #dff5f8;
		border-radius: 50%;

		&.roundBlue {
			color: #fff;
			background-color: #2d5086;
		}

		&.rectangleSmallBlue {
			right: initial;
			left: 0px;
			top: 4px;
		}
	}
}

.video-dating-banner-strip {
	margin: 0 7px;
	position: fixed;
	bottom: 0;
	z-index: 222;
	display: flex;
	align-items: center;
	width: calc(100% - 14px);
	padding: 3px 10px;
	padding-bottom: calc(3px + calc(env(safe-area-inset-bottom) / 2));
	background: linear-gradient(90deg, #2d5086, #0bc1c7);
	border-radius: 12px;
	border: 3px solid #dff5f8;
	color: white;
	gap: 20px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);

	.image-text-box {
		width: 100%;
		margin-right: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.text-box {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.title {
				font-size: 14px;
				font-weight: bold;
				color: #fff;
				line-height: 1;
			}

			.online-button {
				margin-top: 0px;
				border-color: #f65368 !important;
				background-color: #f65368 !important;
				color: white;
				font-weight: bold;
				border-radius: 25px;
				line-height: 1;
				transform: rotate(4deg);
			}
		}
		.img-box {
			display: flex;
			align-items: center;

			img {
				width: fit-content;
			}
		}
	}

	.time-boxes {
		display: flex;
		padding: 4px;
		gap: 4px;

		.time-box {
			border: #fff 1px solid;
			background: rgba(255, 255, 255, 0);
			border-radius: 8px;
			text-align: center;
			padding: 5px;
			min-width: 35px;
			h6 {
				color: #fff;
				font-size: 19px;
				line-height: 1;
				font-weight: bold;
			}

			p {
				line-height: 1;
				color: #fff;
				font-size: 10px;
			}
		}
	}

	&.isCompleteVideoDate {
		padding: 16px 10px 0;
		padding-bottom: calc(0 + calc(env(safe-area-inset-bottom) / 2));
		background: #dff5f8;

		.image-text-box {
			align-items: start;
			justify-content: start;
			flex-direction: column;
			gap: 5px;

			.text-box {
				.title {
					font-size: 16px;
					color: #2d5086;
				}

				.online-button {
					position: absolute;
					top: -25px;
					right: -10px;
					border-radius: 10px;
				}
			}
		}

		.time-boxes {
			.time-box {
				border: #2d5086 1px solid;
				background: rgba(255, 255, 255, 0);
				h6 {
					color: #2d5086;
				}

				p {
					line-height: 1;
					color: #2d5086;
				}
			}
		}
	}
}

//
// chat-leftsidebar.scss
//

.chat-leftsidebar {
	height: 100vh;
	background-color: $sidebar-sub-bg;

	@media (min-width: 992px) {
		min-width: 380px;
		max-width: 380px;
	}

	.user-status-box {
		background-color: $mint;
		padding: 8px;
		border-radius: 8px;
		text-align: center;
		margin-top: 16px;
		display: block;

		.chat-user-img {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
		}
	}
}

.chat-search-box {
	.form-control {
		border: 0;
	}

	.search-icon-btn {
		font-size: 16px;
		position: absolute;
		right: 13px;
		top: 0;
	}
}

.chat-message-list {
	height: calc(100vh - 219px);
	@media (max-width: 991.98px) {
		height: calc(100vh - 194px);
	}
}

/*Ayelet*/
.recommendedUsers ~ .px-2 .chat-message-list {
	height: calc(100vh - 167.19px - 5rem);
	@media (max-width: 991.98px) {
		height: calc(100vh - 279.69px);
	}
}

.chat-group-list {
	height: calc(100vh - 160px);
	@media (max-width: 991.98px) {
		height: calc(100vh - 194px);
	}
}

.chat-list {
	margin: 0;

	li {
		&.active {
			a {
				background-color: $mint;
			}
		}

		a {
			position: relative;
			display: block;
			padding: 17px 20px 20px 25px;
			color: $gray-600;
			transition: all 0.4s;
			border-bottom: 1px solid $mint;
			border-radius: 4px;

			&:hover {
				background-color: $mint;
			}
			@media screen and (max-width: 780px) {
				padding: 13px 20px 15px 25px;
			}

			.wrapperOfchatItem {
				align-items: center;
			}
		}

		.chat-user-message {
			font-size: 14px;
		}

		&.typing {
			.chat-user-message {
				color: $primary;
				font-weight: $font-weight-medium;
				/*Ayelet*/
				font-weight: $font-weight-normal;
				color: $success;

				.dot {
					background-color: $primary;
					/*Ayelet*/
					background-color: $success;
				}
			}
		}

		.unread-message {
			position: absolute;
			display: inline-block;
			right: 24px /*rtl:auto*/;
			left: auto /*rtl:0*/;
			top: 33px;

			.badge {
				line-height: 16px;
				font-weight: $font-weight-semibold;
				font-size: 10px;
				/*Ayelet*/
				width: 24px;
				height: 24px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
		.promoted {
			position: absolute;
			top: 28px;
			right: 20px;
			left: auto;
			height: 10px;
			border-radius: 120px;
			margin-top: 5px;
			padding: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: $secondary;

			span {
				color: white;
			}
		}
	}
}

.chat-user-img {
	position: relative;

	.user-status {
		width: 12px; /*Ayelet*/
		height: 12px; /*Ayelet*/
		background-color: $gray-500;
		border-radius: 50%;
		border: 2px solid $card-bg;
		position: absolute;
		right: 0 /*rtl:auto*/;
		left: auto /*rtl:0*/;
		bottom: 0;
	}

	&.online {
		.user-status {
			background-color: $success;
		}
	}

	&.away {
		.user-status {
			background-color: $warning;
		}
	}
	img {
		object-fit: cover;
	}
}

.wrapChatLine .user-status {
	width: 17px; /*Ayelet*/
	height: 17px; /*Ayelet*/
}

// contact list

.contact-list {
	li {
		cursor: pointer;
		padding: 10px 20px;
	}
}

// setting

.profile-user {
	position: relative;
	display: inline-block;

	.profile-photo-edit {
		position: absolute;
		right: 0 /*rtl:auto*/;
		left: auto /*rtl:0*/;
		bottom: 0;
	}
}
